import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { SOCKET_EVENTS } from "../../helpers/constant";
import { Box, Grid, Typography } from "@mui/material";
import Game from "../../components/Game/Game";

export default function Games({
    header = "",
    theme,
    uiLabels,
    socketFromStore,
    gridSize = { xs: 12, md: 4 },
}) {
    const replayGames = useSelector(({ game }) => game.replayGames);

    useEffect(() => {
        if (!socketFromStore) {
            return;
        }

        socketFromStore.emit(SOCKET_EVENTS.REPLAY_GAMES);
    }, [socketFromStore]);

    return (
        <Box>
            <Typography
                variant="h5"
                sx={{ letterSpacing: 2, fontFamily: theme.font.family }}
            >
                {header}
            </Typography>
            <Grid container spacing={1}>
                {replayGames.map((game) => {
                    return (
                        <Grid
                            key={game["_id"]}
                            item
                            xs={gridSize.xs}
                            md={gridSize.md}
                        >
                            <Game
                                game={game}
                                uiLabels={uiLabels}
                                ogGameDate={game["_id"]}
                                theme={theme}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}

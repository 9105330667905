import React, { useCallback, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Link,
    Typography,
} from "@mui/material";

import { getLabel } from "../../helpers/Global";
import cssStyle from "./Game.module.css";
import useSocketEvents from "../../hooks/useSocketEvents";
import { GAME_STATUS, SOCKET_ACTIONS } from "../../helpers/constant";

export default function Game({ game, uiLabels, ogGameDate, theme }) {
    const [gameStatus, setGameStatus] = useState(null);
    const [hour, setHour] = useState("00");
    const [minute, setMinute] = useState("00");
    const [seconds, setSeconds] = useState("00");

    //callback when questions are updated
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        ogGameDate,
        null,
        (type, payload) => {
            switch (type) {
                case "status":
                    console.log("^^^^^^^^^^^^^^", type, payload);
                    setGameStatus(payload ?? null);
                    break;

                case "countdown":
                    setGameStatus(payload.status ?? null);
                    const [hour, minute, second] = payload.countdown.split(":");
                    setHour(hour);
                    setMinute(minute);
                    setSeconds(second);
                    break;
            }
        }
    );

    const handleJoinGame = () => {
        window.top.location = `${process.env.REACT_APP_WM_URL}/play/${game["_id"]}`;
    };

    const getButtonLabel = useCallback(() => {
        switch (gameStatus) {
            case GAME_STATUS.COUNTDOWN:
                return (
                    <Button
                        className={`btnJoinGame`}
                        variant="contained"
                        sx={theme.home.buttons.replayJoinButton}
                        onClick={handleJoinGame}
                    >
                        {getLabel(uiLabels, "btnJoinGame", "Join Game")}
                    </Button>
                );

            case GAME_STATUS.QUESTIONS:
            case GAME_STATUS.ROUND_ENDING:
            case GAME_STATUS.ROUND_WAITING:
                return (
                    <Button
                        className={`btnJoinGame`}
                        variant="contained"
                        sx={theme.home.buttons.replayButton}
                        disabled={true}
                    >
                        {getLabel(uiLabels, "btnJoinGame1", "Game Started")}
                    </Button>
                );

            default:
                return (
                    <Button
                        className={`btnJoinGame`}
                        variant="contained"
                        sx={theme.home.buttons.replayButton}
                        onClick={handleJoinGame}
                    >
                        {getLabel(uiLabels, "btnPlay", "PLay")}
                    </Button>
                );
        }
    }, [gameStatus]);

    return (
        <Card
            sx={{
                backgroundColor: "#000",
                padding: "3px",
                borderRadius: "0.5rem",
            }}
        >
            <CardContent sx={{ padding: 0, position: "relative" }}>
                <Link href="#" onClick={handleJoinGame}>
                    <img
                        src={game.gameThumbnail}
                        alt={game.subCategory}
                        style={{ width: "100%" }}
                    />
                    <Box className={cssStyle.containerTitle}>
                        <Typography sx={{ color: "white" }}>
                            {`${game.title ?? game.subCategory} (${
                                game.friendlyDate
                            })`}
                        </Typography>
                    </Box>
                    {[GAME_STATUS.WAITING, GAME_STATUS.COUNTDOWN].includes(
                        gameStatus
                    ) && (
                        <Box
                            sx={{
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                backgroundImage: `linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.8866341214220063) 35%)`,
                                paddingRight: "4px",
                            }}
                        >
                            <Typography
                                sx={{ color: "white", textAlign: "right" }}
                            >
                                {`Game starts in ${hour}:${minute}:${seconds}`}
                            </Typography>
                        </Box>
                    )}
                </Link>
            </CardContent>
            <CardActions>{getButtonLabel()}</CardActions>
        </Card>
    );
}

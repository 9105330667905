import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Container, CssBaseline, Grid, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import SnackBar from "../../helpers/SnackBar";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { useSelector, useDispatch } from "react-redux";

import CountdownHeader from "../../components/CountdownHeader/CountdownHeader";
import HomeLogin from "../../components/HomeLogin/HomeLogin";
import HomeCountdown from "../../components/HomeCountdown/HomeCountdown";
import HomeBottomTab from "../../components/HomeBottomTab/HomeBottomTab";
import YoutubeVideo from "../../components/YoutubeVideo/YoutubeVideo";

import useSocketEvents from "../../hooks/useSocketEvents";

//bg images
import circleBG from "./public-trivia-bg-01_255x252.png";
import squareBG from "./public-trivia-bg-02_322x306.png";

import cssStyle from "./Home.module.css";

import {
    CHANGE_NOTIFICATION_PERMISSION,
    GAME_STATUS,
    GET_UI_LABELS,
    NOTIFICATION_ERROR,
    NOTIFICATION_PERMISSION,
    REQUEST_NOTIFICATION_PERMISSION,
    SOCKET_ACTIONS,
    SOCKET_EVENTS,
    SUPER_GAME_TEXT,
    coin,
} from "../../helpers/constant";
import {
    getLabel,
    isCashBattle,
    joinQueue,
    toggleMojoPoints,
} from "../../helpers/Global";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import { addAlert, removeAlert } from "../../reducers/AlertReducer";
import HomeCategoryPrize from "../../components/HomeCategoryPrize/HomeCategoryPrize";
import HowToPlay from "../../components/HowToPlay/HowToPlay";
import WhatAreMojoPoints from "../../components/WhatAreMojoPoints/WhatAreMojoPoints";
import AlertCookie from "../../components/AlertCookie/AlertCookie";
import HomeHeader from "../../components/HomeHeader/HomeHeader";

import { updateNotification } from "../../reducers/GameReducer";

import CashBattleInfo from "../../components/CashBattleInfo/CashBattleInfo";
import HomeWeeklyLeaderboard from "../../components/HomeWeeklyLeaderboard/HomeWeeklyLeaderboard";
import OnlinePlayers from "../../components/OnlinePlayers/OnlinePlayers";
import GameProgress from "../../components/GameProgress/GameProgress";
import GamesTab from "../../components/GamesTab/GamesTab";

export default function Home({
    uiLabels,
    socketFromStore,
    joinCode,
    ogGameDate,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [declinedPoints, setDeclinedPoints] = useState(false);
    const [howToPlay, setHowToPlay] = useState(false);
    const [whatAreMojoPoints, setWhatAreMojoPoints] = useState(false);
    const mainContainer = useRef();

    const error = useSelector(({ message }) => message.error);
    const message = useSelector(({ message }) => message.message);
    const openSnackbar = useSelector(({ message }) => message.openSnackbar);
    const player = useSelector(({ player }) => player);

    const gameStatus = useSelector(({ game }) => game.gameStatus);
    const serverStatus = useSelector(({ game }) => game.serverStatus);
    const questionIndex = useSelector(({ game }) => game.questionIndex);
    const triviaLivestreams = useSelector(({ game }) => game.triviaLivestreams);
    const round = useSelector(({ game }) => game.round);
    const roomId = useSelector(({ game }) => game.roomId);

    const currentTitle = useSelector(({ settings }) => settings.currentTitle);
    const gameThumbnail = useSelector(({ game }) => game.gameThumbnail);
    const superGame = useSelector(({ game }) => game.superGame);
    const requiredPoints = useSelector(({ game }) => game.requiredPoints);

    const handleJoinQueue = useCallback(
        (payWithPoints) => {
            setDeclinedPoints(false);
            joinQueue(socketFromStore, roomId, joinCode, payWithPoints);
        },
        [socketFromStore, joinCode, roomId]
    );

    const handleHowToPlay = () => {
        setHowToPlay(true);
    };

    const handleMojoPoints = () => {
        setWhatAreMojoPoints(true);
    };

    //add event for requiring points to play
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_EVENTS.REQUIRE_POINTS,
        null,
        ({ message, requiredPoints }) => {
            dispatch(
                addAlert({
                    title: `Trivia ${getLabel(
                        uiLabels,
                        "labelCashBattle",
                        "Cash Battle"
                    )} ${`<img src="/image/power-hour.png" class="cash-battle-icon" alt="Cash Battler" />`}`,
                    message: getLabel(
                        uiLabels,
                        "messageRequiredPoints",
                        message
                    ).replace(/#0/g, requiredPoints),
                    buttons: [
                        {
                            text: getLabel(uiLabels, "btnCancel", "Cancel"),
                            callback: () => {
                                dispatch(removeAlert());
                                setDeclinedPoints(true);
                            },
                        },
                        {
                            text: getLabel(
                                uiLabels,
                                "labelHowToPlay",
                                "How to Play"
                            ),
                            callback: () => {
                                setHowToPlay(true);
                            },
                        },
                        {
                            text: getLabel(
                                uiLabels,
                                "whatAreMojoPointsTitle",
                                "What are MojoPoints?"
                            ).replace(/#0/g, `${coin}&nbsp;`),
                            callback: () => {
                                setWhatAreMojoPoints(true);
                            },
                        },
                        {
                            text: getLabel(
                                uiLabels,
                                "btnRedeemPoints",
                                "Redeem Points"
                            ),
                            callback: () => {
                                handleJoinQueue(true);
                                dispatch(removeAlert());
                            },
                        },
                    ],
                })
            );
        }
    );

    //add event for insufficient points to play
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_EVENTS.INSUFFICIENT_POINTS,
        null,
        ({ message, requiredPoints, points }) => {
            message = message.replace(/#0/g, requiredPoints);
            message = message.replace(/#1/g, points);
            dispatch(
                addAlert({
                    message: message,
                    buttons: [
                        {
                            text: getLabel(
                                uiLabels,
                                "whatAreMojoPointsTitle",
                                "What are MojoPoints?"
                            ).replace(/#0/g, `${coin}&nbsp;`),
                            callback: () => {
                                setWhatAreMojoPoints(true);
                            },
                        },
                        {
                            text: getLabel(uiLabels, "btnOk", "Ok"),
                            callback: () => {
                                dispatch(removeAlert());
                                setDeclinedPoints(true);
                            },
                        },
                    ],
                })
            );
        }
    );

    //MESSAGE FUNCTIONS

    const handleMessage = (event) => {
        const payload = event.data;

        if (payload.action) {
            switch (payload.action) {
                case CHANGE_NOTIFICATION_PERMISSION:
                    dispatch(updateNotification(payload.permission));
                    break;

                case NOTIFICATION_PERMISSION:
                    dispatch(updateNotification(payload.permission));

                    if (
                        payload.permission === "default" &&
                        payload.hasOwnProperty("prompt") &&
                        payload.prompt === 0
                    ) {
                        dispatch(
                            addAlert({
                                message: getLabel(
                                    uiLabels,
                                    "messageNotifyMe",
                                    `Notify me when a ${SUPER_GAME_TEXT} starts?`
                                ),
                                buttons: [
                                    {
                                        text: "Yes",
                                        callback: () => {
                                            dispatch(removeAlert());
                                            let message = {
                                                action: REQUEST_NOTIFICATION_PERMISSION,
                                            };
                                            //method to send message to parent outside iframe
                                            window.top.postMessage(
                                                message,
                                                "*"
                                            );
                                        },
                                    },
                                    {
                                        text: "No",
                                        callback: () => {
                                            dispatch(removeAlert());
                                        },
                                    },
                                ],
                            })
                        );
                    }
                    break;

                case NOTIFICATION_ERROR:
                    dispatch(
                        addAlert({
                            message: payload.message,
                            buttons: [
                                {
                                    text: "Ok",
                                    callback: () => {
                                        dispatch(removeAlert());
                                    },
                                },
                            ],
                        })
                    );
                    break;

                default:
                    break;
            }
        }
    };

    useEffect(() => {
        toggleMojoPoints(false);

        window.addEventListener("message", handleMessage);

        let message = {
            action: NOTIFICATION_PERMISSION,
        };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");

        message = {
            action: GET_UI_LABELS,
        };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);
    //MESSAGE FUNCTIONS

    useEffect(() => {
        //don't connect to socket until server is ready
        if (!serverStatus) {
            return;
        }

        if (!socketFromStore) {
            return;
        }
        console.log("SERVER STATUS", serverStatus);
        handleJoinQueue(false);
    }, [socketFromStore, joinCode, serverStatus, roomId]);

    //method to create countdown section
    function countdown() {
        return (
            <>
                <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        sx={{
                            px: theme.space.home.px,
                        }}
                    >
                        <Box>
                            <HomeCategoryPrize
                                subCategory={currentTitle}
                                gameThumbnail={gameThumbnail}
                                uiLabels={uiLabels}
                                theme={theme}
                                requiredPoints={requiredPoints}
                            />
                        </Box>

                        <CountdownHeader
                            requiredPoints={requiredPoints}
                            gameStatus={gameStatus}
                            player={player}
                            questionIndex={questionIndex + 1}
                            uiLabels={uiLabels}
                            round={round + 1}
                        />

                        {[GAME_STATUS.WAITING, GAME_STATUS.COUNTDOWN].includes(
                            gameStatus
                        ) && (
                            <HomeCountdown
                                gameStatus={gameStatus}
                                uiLabels={uiLabels}
                                listenerEvent={
                                    gameStatus === GAME_STATUS.COUNTDOWN
                                        ? SOCKET_EVENTS.START_COUNTDOWN
                                        : SOCKET_EVENTS.WAITING_COUNTDOWN
                                }
                            />
                        )}

                        {gameStatus &&
                            ![
                                GAME_STATUS.COUNTDOWN,
                                GAME_STATUS.WAITING,
                            ].includes(gameStatus) && (
                                <GameProgress
                                    uiLabels={uiLabels}
                                    me={player}
                                    gameStatus={gameStatus}
                                    requiredPoints={requiredPoints}
                                />
                            )}

                        {(!gameStatus ||
                            ![GAME_STATUS.COUNTDOWN].includes(gameStatus)) &&
                            !isCashBattle(requiredPoints) &&
                            player.playerQueue && (
                                <Box>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: {
                                                xs: "16px",
                                                md: "16px",
                                            },
                                            fontWeight: 500,
                                            width: "100%",
                                            textAlign: {
                                                xs: "center",
                                                md: "center",
                                            },
                                        }}
                                    >
                                        {`${getLabel(
                                            uiLabels,
                                            "labelOnlinePlayers",
                                            "Challenge online players to start a game!"
                                        )}`}
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: {
                                                xs: window.innerWidth,
                                                sm: "485px",
                                            },
                                            pt: 1,
                                        }}
                                    >
                                        <OnlinePlayers
                                            me={player}
                                            uiLabels={uiLabels}
                                            gameStatus={gameStatus}
                                            socketFromStore={socketFromStore}
                                        />
                                    </Box>
                                </Box>
                            )}
                    </Box>
                </Grid>
            </>
        );
    }

    //method to create login section or player info (player already logged in)
    function loginPlayer() {
        return (
            <Grid item xs={12} md={12} sx={{ mt: 3, px: theme.space.home.px }}>
                <HomeLogin
                    player={player}
                    declinedPoints={declinedPoints}
                    handleJoinQueue={handleJoinQueue}
                    handleHowToPlay={handleHowToPlay}
                    handleMojoPoints={handleMojoPoints}
                    superGame={superGame}
                    uiLabels={uiLabels}
                />
            </Grid>
        );
    }

    return (
        /* if no error, lobby page, else errorPage */
        error === "" ? (
            <>
                <CssBaseline />
                <Container
                    sx={{
                        position: "relative",
                        padding: "0px !important",
                        width: "100% !important",
                        maxWidth: {
                            xs: "100% !important",
                            md: "100% !important",
                        },
                        height: "auto",
                    }}
                    ref={mainContainer}
                >
                    <img
                        src={circleBG}
                        className={cssStyle.circleBG}
                        alt="bg1"
                    />
                    <img
                        src={squareBG}
                        className={cssStyle.squareBG}
                        alt="bg2"
                    />
                    <Box sx={{ px: theme.space.home.px }}>
                        {player &&
                            player.playerId &&
                            !player.hasConsent &&
                            !isNaN(player.playerId) && (
                                <AlertCookie uiLabels={uiLabels} />
                            )}
                    </Box>
                    <Grid
                        container
                        spacing={0}
                        sx={{
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Grid item xs={12} sx={{ px: theme.space.home.px }}>
                            <HomeHeader
                                uiLabels={uiLabels}
                                requiredPoints={requiredPoints}
                                theme={theme}
                                ogGameDate={ogGameDate}
                            />
                        </Grid>

                        {/* show countdown section */}
                        {countdown()}

                        {/* show player info */}
                        {loginPlayer()}

                        <Grid item xs={12}>
                            <Box sx={{ p: 3 }}>
                                <CashBattleInfo uiLabels={uiLabels} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <HomeBottomTab
                                player={player}
                                uiLabels={uiLabels}
                                theme={theme}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                mt: 3,
                                px: theme.space.home.px,
                            }}
                        >
                            <GamesTab
                                uiLabels={uiLabels}
                                theme={theme}
                                socketFromStore={socketFromStore}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mt: 3,
                                px: theme.space.home.px,
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    mb: 1,
                                    letterSpacing: 2,
                                    fontFamily: theme.font.family,
                                }}
                            >
                                {getLabel(
                                    uiLabels,
                                    "labelTopPlayers",
                                    "Top 10 Players"
                                )}
                            </Typography>
                            <HomeWeeklyLeaderboard
                                uiLabels={uiLabels}
                                player={player}
                            />
                        </Grid>
                        <Grid
                            className="container-livestream"
                            item
                            xs={12}
                            sx={{
                                mt: 3,
                                px: theme.space.home.px,
                            }}
                        >
                            <YoutubeVideo
                                triviaLivestreams={triviaLivestreams}
                                uiLabels={uiLabels}
                            />
                        </Grid>
                    </Grid>
                    {message !== "" && (
                        <SnackBar open={openSnackbar} message={message} />
                    )}

                    <AlertDialog />
                    <HowToPlay
                        show={howToPlay}
                        handleClose={() => {
                            setHowToPlay(false);
                        }}
                        uiLabels={uiLabels}
                    />
                    <WhatAreMojoPoints
                        show={whatAreMojoPoints}
                        handleClose={() => {
                            setWhatAreMojoPoints(false);
                        }}
                        uiLabels={uiLabels}
                    />
                </Container>
            </>
        ) : (
            <ErrorPage />
        )
    );
}
